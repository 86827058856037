import { BlockNoteEditor } from "@blocknote/core";
import {
  BlockNoteView,
  DragHandle,
  FormattingToolbarPositioner,
  HyperlinkToolbarPositioner,
  SideMenu,
  SideMenuButton,
  SideMenuPositioner,
  SideMenuProps,
  SlashMenuPositioner,
  useBlockNote,
} from "@blocknote/react";
import "@blocknote/react/style.css";
import { MdOutlineCloudDownload } from "react-icons/md";
import * as React from "react";
import { gatherPrompt } from "../state";

export const CustomSideMenu = (props: SideMenuProps) => {
  // console.log("props", props);
  return (
  <SideMenu>
    <SideMenuButton>
    <MdOutlineCloudDownload
  size={24}
  onClick={async (e) => {
    const [newBlock] = props.editor.insertBlocks([{type: "paragraph", content: "..."}], props.block.id, "after")
    console.log('newBlock', newBlock)
    try {
    const prompt = await gatherPrompt(props.editor, props.block)
    const response = await fetch("0.0.0.0:1999", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ prompt: prompt}),
    });

    const reader = response.body?.getReader();
    let text = "";

    while (true) {
      const { done, value } = await reader.read();

      if (done) {
        break;
      }

      text += new TextDecoder().decode(value);
      const data = JSON.parse(text);

      // strip whitespace from the beginning of the texxt
      data.text = data.text.replace(/^\s+/, "").split('\n\n')[0]
      console.log("data", data);
      console.log("newBlock.id", newBlock);
    props.editor.updateBlock(newBlock.id, { content: [data.text]},)
    } 
  }
  catch (e) {
    console.log(e)
    props.editor.removeBlocks(newBlock.id)
  }}}
/>
    </SideMenuButton>
    
    <DragHandle {...props} />
  </SideMenu>
);
}
