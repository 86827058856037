import * as React from "react";
import { createRoot } from "react-dom/client";
import YPartyKitProvider from "y-partykit/provider";
import { BlockNoteView, FormattingToolbarPositioner, HyperlinkToolbarPositioner, SideMenuPositioner, SlashMenuPositioner, useBlockNote } from "@blocknote/react";
import * as Y from "yjs";

import { fireproof } from "use-fireproof";
import { connect } from "@fireproof/partykit"

import "@blocknote/core/style.css";
import "@blocknote/react/style.css";


import { getRandomUser } from "./randomUser";
import { Block, BlockNoteEditor, BlockSchemaFromSpecs, InlineContentSchema, InlineContentSchemaFromSpecs, StyleImplementation, StyleSchema, StyleSchemaFromSpecs, TiptapBlockImplementation } from "@blocknote/core";
import { JSONTree } from 'react-json-tree';
import { SIGNAL } from "./ipsum";
import { CustomSideMenu } from "./components/CustomSideMenu";
import { gatherPrompt } from "./state";

declare const PARTYKIT_HOST: string;

const doc = new Y.Doc();
const provider = new YPartyKitProvider(PARTYKIT_HOST, "my-document-id", doc);

const database = fireproof("my-document-id");
const connection = connect.partykit(database, PARTYKIT_HOST);

function Editor() {
  // Creates a new editor instance.
  const editor = useBlockNote({
    // initialContent: SIGNAL as any,
    collaboration: {
      // The Yjs Provider responsible for transporting updates:
      provider,
      // Where to store BlockNote data in the Y.Doc:
      fragment: doc.getXmlFragment("document-store"),
      // Information (name and color) for this user:
      user: getRandomUser()
    },
 
});

  
  return (<>
  <BlockNoteView editor={editor} theme={"light"}>
      <FormattingToolbarPositioner editor={editor} />
      <HyperlinkToolbarPositioner editor={editor} />
      <SlashMenuPositioner editor={editor} />
      <SideMenuPositioner editor={editor} sideMenu={CustomSideMenu} />
    </BlockNoteView>
<button onClick={async () => {
  const markdown = await editor.blocksToMarkdownLossy(editor.topLevelBlocks);
  const element = document.createElement("a");
  const file = new Blob([markdown], {type: 'text/plain'});
  element.href = URL.createObjectURL(file);
  element.download = "myFile.md";
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
}}>
  Download Markdown
</button>
<button onClick={async () => {
  const blocks = editor.topLevelBlocks;
  const element = document.createElement("a");
  const file = new Blob([JSON.stringify(blocks)], {type: 'application/json'});
  element.href = URL.createObjectURL(file);
  element.download = "myFile.json";
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
}}>
  Download Blocks as JSON
</button>

</>
)  ;
  
}

const root = createRoot(document.getElementById("root")!);
root.render(<Editor />);
