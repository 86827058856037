import { Block } from "@blocknote/core";

const windowLength = 8000;

export const gatherPrompt = async (editor, currentBlock) => {
  let blocksToCursor = Array<string>();
  let totalLength = 0;
  console.log("currentBlock", currentBlock);
  // this does dfs and we want to collect all the unique ids of the blocks that are in the path to the cursor
  editor.forEachBlock((block: Block) => {
    if (block.content.length > 0) {
      // console.log("block content", block.content);
      // totalLength += block.content[0].text.length;

      // we have a series of blocks, where each one has a content[0].text that's 0 or more characters long
      // we need to keep the total length within the windowLength
      // but we have to pop blocks off the front, not just characters
      totalLength += block.content[0].text.length;
      while (totalLength > windowLength) {
        const removedBlock = blocksToCursor.shift();
        // console.log("removedBlock", removedBlock);
        totalLength -= editor.getBlock(removedBlock).content[0].text.length;
        // console.log("totalLength", totalLength);
      }
    }
    // console.log("block", block);
    blocksToCursor.push(block.id);
    if (block.id === currentBlock.id) {
      console.log("found cursor");
      return false;
    } else return true;
  });
  console.log("blocksToCursor", blocksToCursor);

  // we filter the top level blocks by if they're in the
  const topLevelBlocksToCursor = editor.topLevelBlocks.filter((block) =>
    blocksToCursor.includes(block.id)
  );
  console.log("topLevelBlocksToCursor", topLevelBlocksToCursor);

  const md = await editor.blocksToMarkdownLossy(topLevelBlocksToCursor);
  // console.log("md", md);
  // console.log("cursor text",);
  // split the markdown at the text of cursor, then add the text of cursor at the end
  console.log("currentBlock", currentBlock);
  const currentBlockText = currentBlock.content
    ? currentBlock.content[0].text
    : "\n";

  const [pre, post] = md.split(currentBlockText);
  const totalBeforeCursor = pre + currentBlockText;
  const finalPrompt = totalBeforeCursor.slice(-windowLength);
  console.log("finalPrompt", finalPrompt);
  return finalPrompt;
};
